<template>
  <div class="page data-table-container">
    <div class="tools-bar">
      <el-button-group>
        <router-link class="el-button el-button--primary" to="/channel-info">创建
        </router-link>
        <el-button @click="load">刷新</el-button>
      </el-button-group>
    </div>

    <el-table class="table" :data="tableData" @row-click="onClickRow"
              fit highlight-current-row>
      <el-table-column align="center" label="CID" width="70" prop="id"/>
      <el-table-column align="center" label="排序" width="70" prop="i_order"/>
      <el-table-column label="专栏名称" prop="title"/>
      <el-table-column label="作者" prop="user.realname" width="120"/>
      <el-table-column label="课程" width="80" prop="stat_course" sortable/>
      <el-table-column label="粉丝" width="80" prop="stat_fans" sortable/>
      <el-table-column label="推广注册" width="100" prop="tg_num" sortable/>
      <el-table-column label="推广VIP" width="100" prop="tg_vip_num" sortable/>
      <el-table-column label="推广商品" width="100" prop="tg_goods_num" sortable/>
      <!--      <el-table-column label="上次更新" width="160" prop="datetime_updated" sortable/>-->
    </el-table>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        inputSearch: '',
        tableData: [],
        page: 1,
        total: 0,
        loading: false,
        userLoading: false
      }
    },
    mounted() {
      this.load()
    },
    methods: {
      async load() {
        let tableData = await this.$api.request('/channel/list_simple')
        if (this.$dbus.account.role === '啦啦之星') {
          this.tableData = tableData.filter(r => r.user.id === this.$dbus.account.id)
        } else {
          this.tableData = tableData
        }
      },
      onClickRow({id}) {
        this.$router.push('/channel-info?id=' + id)
      },
      onSearch() {
        this.$api.request('/user/search', {
          key: this.inputSearch,
          fields: ['nickname', 'realname', 'tel']
        }).then(data => {
          this.tableData = data
          this.page = 1
          this.total = data.length
        })
      }
    }
  }
</script>
